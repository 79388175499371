<template>
  <div>
    <!-- Brand Logo -->
    <template v-if="column.content && column.content.length">
      <div
        class="row no-gutters justify-content-center"
        v-bind:style="{
          gap: `${column.style.rowGap}px ${column.style.columnGap}px`,
        }"
      >
        <div v-for="brand in column.content" v-bind:key="brand.id">
          <div class="col">
            <div class="card">
              <div class="card-body">
                <img
                  v-if="brand.image && brand.image.path"
                  v-bind:src="brand.image.path.medium"
                  width="100px"
                />
                <span v-else>{{ brand.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- ./Brand Logo -->

    <!-- Selected Brands -->
    <div class="mt-3">
      <label class="custom-label">Seçili Markalar</label>
      <v-select
        label="name"
        v-bind:multiple="true"
        v-bind:options="brand.list"
        v-model="column.content"
      />
    </div>
    <!-- ./Selected Brands -->
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "BrandColumn",
  props: {
    column: {
      default: () => {},
    },
  },
  model: {
    prop: "column",
  },
  computed: {
    ...mapState(["brand"]),
  },
};
</script>